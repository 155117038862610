/* eslint no-invalid-this: 0 */
(function ($) {
  'use strict';

  window.scala.documentReady.then(function () {
    // Navigation principale
    var $mainContent = $('#main');
    var $navWrap = $('.main-navigation');
    var $navigation = $('.main-navigation__items');
    var $mainItem = $navigation.find('.main-navigation__item > a');
    var $subMenu = $mainItem.next('.submenu');
    var menuOpen = false;
    var mobileNavigation = false;
    var device = '';
    var windowHeight = 0;
    var topbarHeight = 0;


    // identification de l'écran
    var getDevice = function () {
      var winSize = $('#site_screen_type').css('font-family');

      winSize = winSize.replace(/"/g, '');
      device = winSize;
    };

    // Disable - enable scroll event
    var current = 0,
        scrolling = true;
    var disableScroll = function() {
      current = $(window).scrollTop();
      $('body').css({'position': 'fixed', 'overflow-y': 'scroll', 'top': -current});
      scrolling = false;
    };
    var enableScroll = function() {
      $('body').css({'position': 'static', 'overflow-y': 'auto', 'top': 0});
      $(window).scrollTop(current);
      scrolling = true;
    };

    // Desktop accordeon nav
    var mainNavigation = function () {
      // var $closeMenu = $subMenu.find('.close_menu');
      var submenuHeight = 0;

      $mainItem.click(function (event) {
        if ($(this).next('.submenu').length !== 0) { // si sous menu
          var $thisParentLi = $(this).parent('li');
          var $thisSubmenu = $(this).next('.submenu');

          event.stopPropagation();
          event.preventDefault();

          if (mobileNavigation) {
            submenuHeight = 0;
          }
          else {
            submenuHeight = $thisSubmenu.outerHeight();
          };

          if ($thisParentLi.hasClass('open')) {
            $mainItem.parent('li').removeClass('open');
            $subMenu.slideUp();
            $mainContent.animate({ 'margin-top': 0 });
            menuOpen = false;
            enableScroll();
          }
          else {
            $mainItem.parent('li').removeClass('open');
            if (mobileNavigation) {
              $subMenu.slideUp();
            } else {
              $subMenu.hide();
            }
            $thisParentLi.addClass('open');
            $thisSubmenu.slideDown();
            $mainContent.animate({ 'margin-top': submenuHeight - 1 });
            menuOpen = true;
            disableScroll();
          };
        }
      });

      $(document).click( function(){ // lorsqu'on clic hors du menu
          if (menuOpen) { // on ferme tout si il est ouvert
            $mainItem.parent('li').removeClass('open');
            $subMenu.slideUp();
            $mainContent.animate({ 'margin-top': 0 });
            menuOpen = false;
            enableScroll();
          }
      });

      $subMenu.click( function(ev){ // lorsqu'on clic dans le menu
          ev.stopPropagation();
      });

      // $closeMenu.click(function(e){
      //     e.preventDefault();
      //     $mainItem.parent('li').removeClass('open');
      //     $subMenu.slideUp();
      // });
    };

    var mobileNavHeight = function () {
        windowHeight = $(window).outerHeight();
        topbarHeight = $('.main-navigation__top').outerHeight() + $('.topbar__holder').outerHeight();
        $navigation.css({'max-height' : windowHeight - topbarHeight +'px'}); // vitrine
        // $('#main-menu-holder').css({'max-height' : windowHeight - topbarHeight +'px'}); // store
        // console.log(windowHeight, topbarHeight);
    }

    // Mobile nav
    var mainNavigationMobile = function () {
      var $buttonMobileNav = $('.burger-btn');
      var $secondLevelItem = $('.submenu__name > a');
      // var $thirdLevelMenu = $secondLevelItem.next('ul');

      $buttonMobileNav.click(function () {
        if (mobileNavigation) {
          mobileNavHeight();
          if ($navigation.hasClass('open')) {
            $navWrap.removeClass('open');
            $navigation.slideUp().removeClass('open');
            $(this).removeClass('open');
            $mainItem.parent('li').removeClass('open');
            $subMenu.slideUp();
            $mainContent.animate({ 'margin-top': 0 });
            // $thirdLevelMenu.slideUp();
            enableScroll();
          }
          else {
            $navWrap.addClass('open');
            $navigation.slideDown().addClass('open');
            $(this).addClass('open');
            disableScroll();
          }
        }
      });

      $secondLevelItem.click(function (event) {
        if (mobileNavigation) {
          if ($(this).next('ul').length !== 0) { // si sous menu
            event.preventDefault();
            if ($(this).next('ul').hasClass('open')) {
              $secondLevelItem.removeClass('open');
              // $thirdLevelMenu.slideUp();
            }
            else {
              $secondLevelItem.removeClass('open');
              // $thirdLevelMenu.slideUp();
              $(this).addClass('open');
              $(this).next('ul').slideDown();
            }
          }
        }
      });

      $mainItem.click(function () { // hide all
        if (mobileNavigation) {
          $secondLevelItem.removeClass('open');
          // $thirdLevelMenu.slideUp();
        }
      });
    };

    var menuSticky = function () {
      var trigger = 100;

      $(window).scroll(function () {
        // console.log('scroll');
        if ($(window).scrollTop() >= trigger) {
          $('#header').addClass('header--sticky');
          mobileNavHeight(); // update mobile height
        } else {
          if (scrolling) {
            $('#header').removeClass('header--sticky');
          }
          mobileNavHeight();
        }
      });
    };

    var toggleStickyTopbar = function () {
      $('.topbar__holder').click( function (event) {
        if ($(this).parent('#header').hasClass('header--sticky')) {
          //  event.stopPropagation();
          if ($(this).hasClass('topbar__holder--open')) {
            if (event.target !== this) {
              return;
            };
            $(this).removeClass('topbar__holder--open');
          } else {
            $(this).addClass('topbar__holder--open');
          }
        }
      })
    }


    // init
    getDevice();
    if (device !== 'screen-large') {
      mobileNavigation = true;
    }

    menuSticky();
    toggleStickyTopbar();
    mainNavigation();
    mainNavigationMobile();

    $(window).resize(function () {
      getDevice()

      if (device !== 'screen-large' && !mobileNavigation) {
        mobileNavigation = true;
      }
      else if (device === 'screen-large') {
        mobileNavigation = false;
        $navWrap.removeClass('open');

      }
    });

  });
}(window.jQuery.noConflict()));
